body {
    background-color: #F4F6F8;
    font-family: 'Mark OT';
}
* {
    box-sizing: border-box;
}
img {
    max-width: 100%;
}

.container {
    max-width: 1000px;
    margin:0 auto;
    padding: 0 15px;
}
img{
    vertical-align: top;
}

.bh-header-main {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.bh-header {
    width: 47%;
    padding-right: 130px;
}

.bh-header-image {
    width: 53%;
}
.bh-header h1 {
    font-size: 38px;
    line-height: 44px;
    color: #E20886;
    margin: 0 0 10px;
}
.bh-header p {
    font-size: 16px;
    line-height: 20px;
    color: #000000;
    margin: 0;
}

.bh-header-image {
    position: relative;
}

.bh-banner-vector {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}
.bh-banner-vector .bh-banner-vector1 {
    position: relative;
    left: -70px;
    z-index: 1;
    height: 100%;
}

.bh-banner-vector .bh-banner-vector2 {
    position: relative;
    left: -131px;
    height: 100%;
}

.bh-banner-vector .bh-banner-vector3 {
    position: relative;
    left: -181px;
    opacity: .8;
    height: 100%;
}
.bh-header-image .bh-banner-img{
    width: 100%;
}
.bh-form-box {
    max-width: 800px;
    margin: 0 auto;
    display: block;
    background: #fff;
    padding: 30px;
    margin-top: 40px;
}
.bh-form-box h3 {
    font-size: 18px;
    line-height: 21px;
    color: #2179BF;
    margin: 0 0 30px;
    max-width: 660px;
}


.bh-select-input .bh-select-box {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 30px;
    align-items: center;
}

.bh-select-input .bh-select-box .bh-title {
    width: 100%;
    max-width: 205px;
    padding-right: 30px;
}
.bh-select-input .bh-select-box .bh-title h4{
    margin: 0;
    font-size: 16px;
    line-height: 18px;
    font-weight: 400;
}
.bh-select-input .bh-select-box .bh-select {
    width: 100%;
    max-width: 240px;
}

.bh-select-input .bh-select-box .bh-select .bh-input {
    width: 100%;
}
.bh-select-input .bh-select-box .bh-select .bh-input .MuiSelect-select,
.bh-select-input .bh-select-box .bh-select .bh-input .MuiInputBase-input {
    padding: 4.5px 14px;
    font-size: 14px;
}
.bh-select-input .bh-select-box .bh-select .bh-input > label {
    top: -11px;
    font-size: 14px;
}
.bh-select-input .bh-select-box .bh-select .bh-input > label.MuiInputLabel-shrink {
    top: 0;
    display: none;
}
.bh-select-input .bh-select-box .bh-select .bh-input fieldset legend {
    display: none;
}
.bh-select-input .bh-select-box .bh-select .bh-input fieldset {
    top: 0;
    border-color: #2179BF;
    border-radius: 8px;
}
.bh-select-input .bh-select-box .MuiFormControlLabel-root {
    margin: 0;
    vertical-align: top;
    align-items: flex-start;
}

.bh-select-input .bh-select-box .MuiFormControlLabel-root .MuiCheckbox-root svg {
    height: 18px;
    width: 18px;
    color: #2179BF;
}
.bh-select-input .bh-select-box .MuiFormControlLabel-root .MuiCheckbox-root {
    padding: 0;
    margin-top: 3px;
    margin-right: 7px;
}

.bh-select-input .bh-select-box .MuiFormControlLabel-root .MuiTypography-root {
    font-size: 14px;
}
.bh-select-box span.bh-pvc {
    font-size: 14px;
    font-weight: 300;
    padding-left: 25px;
}
.bh-form-submit button {
    background: #E20886;
    border-radius: 6px;
    font-size: 14px;
    text-transform: inherit;
    box-shadow: none;
}
.bh-form-submit button:hover, .bh-form-submit button:focus {
    background: #a90865;
}


.bh-result {
    background-color: #fff;
    padding: 30px;
    margin: 30px auto;
    max-width: 800px;
    width: 100%;
}
.bh-download-report h1{
    color: #2179BF;
    font-size: 18px;
    line-height: 21px;
    margin: 0 0 10px;
}
.bh-download-report p{
    color: #000000;
    font-size: 16px;
    line-height: 18px;
    margin: 0 0 15px;
}
.bh-download-report a {
    background: transparent;
    border: 1px solid #E20886;
    color: #E20886;
    font-weight: 600;
    border-radius: 6px;
    font-size: 14px;
    text-transform: inherit;
    box-shadow: none;
    font-family: "Mark OT" !important;
    padding: 8px 20px;
    text-decoration: none;
    display: inline-block;
}
.bh-download-report button:hover {
    background: #E20886;
    color: #ffffff;
}
.bh-question-box {
    margin-top: 50px;
}
.bh-question {
    display: flex;
}

.bh-question .bh-question-icon {
    margin-right: 20px;    
    flex: 0 0 40px;
}

.bh-question h2 {
    color: #2179BF;
    font-size: 16px;
    line-height: 20px;
    margin: 0 0 10px;
    font-weight: 400;
}
.bh-question p {
    color: #000000;
    font-size: 14px;
    margin: 0;
    font-weight: 400;
}
.bh-question-reslt p {
    color: #000000;
    font-size: 12px;
    margin: 0;
    font-weight: 400;
}

.bh-question-reslt {
    margin-bottom: 30px;
}

.bh-discover {
    display: flex;
    flex-wrap: wrap;
    background: #007ACC;
    margin: 0 -30px;
    align-items: center;
}

.bh-discover .bh-download-image {
    max-width: 300px;
}   

.bh-discover .bh-download-content {
    width: calc(100% - 350px);
    padding: 15px;
}

.bh-discover .bh-download-content h3 {
    margin: 0 0 13px;
    color: #fff;
    font-size: 28px;
    line-height: 30px;
}

.bh-discover .bh-download-content p {
    color: #fff;
    margin: 0 0 5px;
    font-size: 15px;
}

.bh-discover .bh-download-content a {
    background: #E20886;
    border: 0;
    color: #ffffff;
    font-weight: 600;
    border-radius: 6px;
    font-size: 14px;
    text-transform: inherit;
    box-shadow: none;
    margin-top: 12px;
    padding: 8px 20px;
    text-decoration: none;
    display: inline-block;
}
.bh-discover .bh-download-content button:hover{
    background-color: #fff;
    color: #E20886;
}
.progress-percentage {
    padding-left: 5px;
    flex: 0 0 75px;
}

.bh-progress-chart {
    margin-top: 30px;
}

.bh-question-box {
    margin-bottom: 0;
}
/* chart */
.progress-percentage button {
    background: #007ACC;
    border-radius: 6px;
    font-size: 15px;
    text-transform: inherit;
    box-shadow: none;
    padding: 0;
    letter-spacing: 0;
    font-weight: 500;
    width: 100%;
    height: 100%;
    text-align: center;
}
.progress-percentage {
    padding-left: 5px;
    flex: 0 0 80px;
}
.progress-bar-main {
    display: flex;
    margin-bottom: 5px;
}
.progress-bar-main-box{
    position:relative; 
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
}
.progress-bar-main-box svg{
    vertical-align: top;
}
.progress-bar-content{
    display: flex;
    align-items: center;
    position: absolute;
    width:100%;
    top: 0;
}
.svg-rotate{
    -webkit-transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  transform: scaleX(-1);
}
.progress-process {
    justify-content: flex-end;
    display: flex;
    width: 60%;
}
.progress-process svg{
    border-radius: 5px;
}
.progress-text {
    width: 40%;
    margin-top: 0;
    font-size: 16px;
}
.progress-text h3 {
    font-size: 16px;
    margin: 0;
}

.donot-clipping {
    position: relative;
    overflow: hidden;
    width: 130%;
    margin-left: -15%;
}
.bh-donot-clip{
    max-width: 230px;
    overflow: hidden;
    width: 100%;
}
.donot-clipping .bh_svgClipping {
    position: absolute;
    left: 15%;
    top: 4%;
    right: 0;
    width: 70%;
    z-index: 4;
    height: auto !important;
    border: 0 solid #fff;
    /* opacity: .5; */
}
.bh_svgClipping svg {
    vertical-align: top;
    box-shadow: 0px 0px 0px 40px #ffffff;
}
.bh-workspace-graph {
    display: flex;
    flex-wrap: wrap;
}

.bh-workspace-graph .bh-donot-clip {
    max-width: 145px;
}

.bh-workspace-graph .donut-chart-box h2,
.bh-workspace-graph .donut-chart-box h3 {
    font-size: 14px;
    text-align: center;
    font-weight: 400;
    color: #2179BF;
    margin: 0 0 0;
}
.bh-workspace-graph .donut-chart-box h3{
    color: #000;
    margin: 0;
}
.bh_chart-per {
    position: absolute;
    left: 0;
    right: 0;
    color: #000;
    z-index: 999;
    top: 48%;
    transform: translateY(-50%);
}
.bh_chart-per h4{
    font-size: 24px;
    text-align: center;
    font-weight: 400;
    color: #000000;
    margin: 0 0 0;
}
.bh-donet-indstry {
    display: flex;
    align-items: center;
    max-width: 600px;
    margin: 0 auto;
}

.bh-donet-indstry .bh-donet-indstry-txt p {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
}

.bh-donet-indstry .bh-donet-indstry-txt {
    padding-left: 30px;
}
.bh-donet-health .bh-donot-clip {
    margin: 0 auto;
}

.bh-donet-health {
    position: relative;
    max-width: 680px;
    margin: 0 auto 0;
}
.bh-donet-health.custom {
    margin: 150px auto 50px;
}

.bh-donet-health-st {
    position: absolute;
    border: 1px solid #e20886;
    width: 100%;
    max-width: 170px;
    text-align: center;
    top: -20px;
    padding: 15px;
    border-radius: 5px;
    z-index: 5;
}

.bh-donet-health-st::before {
    height: 1px;
    width: 26px;
    content: "";
    background: black;
    display: inline-block;
    position: absolute;
    left: auto;
    right: -83px;
    top: 70%;
    transform: translateY(-50%) rotate(34deg);
}
.bh-donet-health-st p {
    margin: 0;
    font-size: 12px;
}

.bh-donet-health-st h4 {
    position: absolute;
    top: 50%;
    left: auto;
    right: -50px;
    transform: translateY(-50%);
    margin: 0;
    font-size: 17px;
    color: #e20886;
}

.bh-donet-health-st.bh-donet-health-st1 {
    bottom: 114%;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: auto;
}
.bh-donet-health-st.bh-donet-health-st1::before {
    transform: inherit;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: auto;
    bottom: -48px;
    width: 1px;
    height: 26px;
}
.bh-donet-health-st.bh-donet-health-st1 h4 {
    top: auto;
    bottom: -25px;
    transform: inherit;
    left: 0;
    right: 0;
    text-align: center;
}
.bh-donet-health-st.bh-donet-health-st2 {
    left: auto;
    right: 10px;
    top: -20px;
}
.bh-donet-health-st.bh-donet-health-st2::before {
    left: -79px;
    right: auto;
    transform: translateY(-50%) rotate(-34deg);
}
.bh-donet-health-st.bh-donet-health-st2 h4 {
    left: -50px;
    right: auto;
}

.bh-donet-health-st.bh-donet-health-st3 {
    left: auto;
    right: 0px;
    top: 110px;
}
.bh-donet-health-st.bh-donet-health-st3::before {
    left: -79px;
    right: auto;
    transform: translateY(-50%) rotate( 36deg);
    top: 44%;
}
.bh-donet-health-st.bh-donet-health-st3 h4 {
    left: -50px;
    right: auto;
}
.bh-donet-health-st.bh-donet-health-st4 {
    left: 0;
    right: 0;
    top: 0;
    margin: 0 auto;
    position: relative;
    margin-top: 20px;
}
.bh-donet-health-st.bh-donet-health-st4::before {
    transform: inherit;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: -54px;
    width: 1px;
    height: 26px;
}
.bh-donet-health-st.bh-donet-health-st4 h4 {
    top: -25px;
    transform: inherit;
    left: 0;
    right: 0;
    text-align: center;
}
.bh-donet-health-st.bh-donet-health-st5 {
    top: 110px;
}
.bh-donet-health-st.bh-donet-health-st5::before {
    transform: translateY(-50%) rotate( 
-27deg);
    top: 47%;
}





.bh-select-li {
    font-size: 14px !important;
    font-family: "Mark OT" !important;
    line-height: 18px !important;
    font-weight: 400 !important;
    padding: 5px 10px !important;
    min-height: 44px !important;
}
.bh-input *, .bh-form-submit button {
    font-family: "Mark OT" !important;
}

.bh-industry-average{
color: #e20886;
}
.bh-national-average{
color: #007acc;
}

text#Manufacturing tspan, text#industry tspan {
    font-size: 10px;
        letter-spacing: -.5px;
}

text#National tspan, text#average tspan {
    font-size: 10px;
        letter-spacing: -.5px;
}




@media(max-width:1019px){
    .bh-header h1 {
        font-size: 22px;
        line-height: 26px;
    }
    .bh-header p {
        font-size: 14px;
        line-height: 18px;
    }
    .bh-header-image .bh-banner-img {
        width: 106%;
        margin-left: -6%;
    }
    .bh-form-box h3 {
        font-size: 16px;
        line-height: 19px;
    }
}

@media(min-width:768px){
    .bh-national-average-app.bh-display-mobile {
        display: none;
    }
    }

@media(max-width:767px){  
    .bh-progress-chart > svg {
        width: 600px;
    } 
    .bh-progress-chart {
        overflow-x: hidden;
    }
    .bh-display-desktop .desk-text {
        display: none;
    }
    .bh-display-mobile p {
        font-size: 14px;
    }
    .bh-donet-health {
        max-width: 570px;
    }
    .bh-donet-health .bh-donot-clip {
        max-width: 180px;
    }
    
    .bh-donet-health-st {
        padding: 10px;
        max-width: 140px;
    }
    
    .bh-donet-health-st::before {
        right: -69px;
    }
    
    .bh-donet-health-st.bh-donet-health-st3::before {
        left: -85px;
        top: 26px;
    }
    .bh-donet-health-st.bh-donet-health-st5::before {
        top: 37%;
        right: -84px;
    }
    
    .bh-donet-health-st.bh-donet-health-st1 {
        bottom: 120%;
    }
    .progress-text {
        width: 40%;
    }
    
    .progress-process {
        width: 60%;
    }
    .bh-discover .bh-download-content h3 {
        font-size: 20px;
        line-height: 22px;
    }
    .bh-header-image {
        width: 100%;
        overflow: hidden;
        margin-bottom: 20px;
    }
    
    .bh-header {
        width: 100%;
        order: 2;
        padding-right: 0;
    }
    
    .bh-header-image .bh-banner-img {
        margin: 0px 0 0 11%;
    }
    
    .bh-banner-vector .bh-banner-vector1 {
        left: 0px;
    }
    
    .bh-banner-vector .bh-banner-vector2 {
        left: -12%;
    }
    
    .bh-banner-vector .bh-banner-vector3 {
        left: -22%;
    }
    .bh-select-li {
        min-height: 34px !important;
    }
}

@media(max-width:639px){
    .bh-display-mobile p {
        font-size: 13px;
    }
    .bh-discover .bh-download-content h3 {
        font-size: 20px;
        line-height: 22px;
    }
    
    .bh-discover .bh-download-image {
        max-width: 100%;
        width: 100%;
    }
    
    .bh-discover .bh-download-content {
        width: 100%;
    }
    
    .progress-percentage button {
        font-size: 12px;
    }
    
    .progress-percentage {
        flex: 0 0 70px;
    }
    
    .bh-question .bh-question-icon {
        flex: 0 0 32px;
        margin-right: 10px;
    }
    
    .bh-question-box {
        margin-top: 30px;
    }
    
    .bh-question h2 {
        font-size: 14px;
        line-height: 18px;
    }
    
    .bh-question p {
        font-size: 13px;
    }
    .bh-progress-chart h3 {
        font-size: 12px;
    }
    
    .bh-donet-indstry {
        flex-wrap: wrap;
    }
    
    .bh-donet-indstry .bh-donet-indstry-txt {
        padding: 0;
    }
    
    .bh-discover .bh-download-content h3 {
        font-size: 18px;
    }
    .bh-download-report p {
        line-height: 16px;
        font-size: 14px;
    }
    .bh-download-report h1 {
        font-size: 16px;
        line-height: 19px;
    }
    .bh-result {
        padding: 15px;
    }
    .bh-form-box {
        padding: 15px;
    }
    
    .bh-select-input .bh-select-box .bh-title {
        max-width: 100%;
        margin-bottom: 6px;
    }
    .bh-header h1 {
        font-size: 20px;
        line-height: 24px;
    }
    .bh_chart-per h4 {
        font-size: 20px;
    }
    .bh-donet-health .bh-donot-clip {
        max-width: 130px;
    }
    .bh-select-input .bh-select-box .bh-title h4 {
        font-size: 14px;
    }
    .bh-donet-health-st {
        padding: 5px;
        max-width: 100px;
        top: -10px;
        left: -14px;
    }
    .bh-donet-health-st::before {
        right: -37px;
        width: 16px;
    }
    
    .bh-donet-health-st p {
        font-size: 10px;
    }
    
    .bh-donet-health-st h4 {
        font-size: 14px;
        right: -34px;
    }
    .bh-donet-health-st.bh-donet-health-st1 h4 {
        bottom: -20px;
    }
    .bh-donet-health-st.bh-donet-health-st1::before {
        height: 16px;
        bottom: -34px;
    }

    .bh-donet-health-st.bh-donet-health-st2 {
        top: -10px;
        right: -12px;
    }
    .bh-donet-health-st.bh-donet-health-st2::before {
        left: -35px;
    }
    .bh-donet-health-st.bh-donet-health-st2 h4 {
        left: -32px;
    }

    .bh-donet-health-st.bh-donet-health-st3 {
        top: 60px;
        right: -13px;
    }
    .bh-donet-health-st.bh-donet-health-st3::before {
        left: -33px;
        top: 14px;
    }
    .bh-donet-health-st.bh-donet-health-st3 h4 {
        left: -39px;
    }

    .bh-donet-health-st.bh-donet-health-st4::before {
        height: 16px;
        top: -32px;
    }
    .bh-donet-health-st.bh-donet-health-st4 h4 {
        top: -18px;
    }

    .bh-donet-health-st.bh-donet-health-st5 {
        top: 60px;
        left: -15px;
    }
    .bh-donet-health-st.bh-donet-health-st5::before {
        top: 28%;
        right: -37px;
    }
    
    .bh-donet-health {
        max-width: 340px;
        margin: 0 auto 0;
    }
    .bh-donet-health.custom {
        margin: 120px auto 120px;
    }
    
    .bh-discover {
        margin: 0 0px;
    }
    .bh-discover .bh-download-image img{
        width: 100%;
    }
}